import styled from 'styled-components'
import { motion } from 'framer-motion'
import StyledTextTitle from '../TextTitle/style'
import { randomTextRotation } from '../helpers/utils'

const StyledResultStepTwo = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;

  ${StyledTextTitle} {
    top: 50%;
    transform: translateY(-190px) rotate(${randomTextRotation()});
  }
`

export default StyledResultStepTwo
