import { randomInt } from '../../../utilities/helpers'

export const characterWrapperVariants = {
  initial: { scaleY: 0.8, opacity: 0 },
  in: {
    scaleY: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 0.6,
      bounce: 0.65,
      velocity: 0,
      delay: 0.4,
    },
  },
  out: {
    opacity: 0,
    transition: { ease: 'linear', duration: 0.15 },
  },
}

export const randomTextRotation = () => {
  return `${randomInt(-12, 12)}deg`
}
