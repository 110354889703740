import { motion } from 'framer-motion'
import StyledStartCharacterHands from './style'
import leftHand from '../../../img/start/leftHand.svg'
import rightHand from '../../../img/start/rightHand.svg'

const StartCharacterHands = (props) => {
  const handVariants = {
    initial: { scaleY: 0.8, opacity: 0, y: -5 },
    in: (i) => ({
      scaleY: 1,
      opacity: 1,
      y: i,
      transition: { ease: 'easeOut', duration: 0.15, delay: 1.25 },
    }),
    out: {
      opacity: 0,
      transition: { ease: 'linear', duration: 0.1 },
    },
  }

  return (
    <StyledStartCharacterHands>
      <motion.img
        key={'leftHand'}
        src={leftHand}
        alt={''}
        style={{ position: 'absolute', x: 71 }}
        variants={handVariants}
        custom={4}
      />
      <motion.img
        key={'rightHand'}
        src={rightHand}
        alt={''}
        style={{ position: 'absolute', x: 213 }}
        variants={handVariants}
        custom={0}
      />
    </StyledStartCharacterHands>
  )
}

export { StartCharacterHands }
