import { useEffect, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { randomFloat, randomInt } from '../../../utilities/helpers'

const getVariants = (yValue) => {
  return {
    close: {
      y: yValue,
      transition: {
        duration: 0.12,
        ease: 'easeInOut',
      },
    },
    open: {
      y: 0,
      transition: {
        duration: 0.2,
        ease: 'easeInOut',
      },
    },
  }
}

const wait = (duration) => {
  return { x: 0, transition: { duration: duration } }
}

const getSequence = (controls, minTime = 0.1, maxTime = 4) => {
  const sequence = async (waitTime) => {
    await controls.start('close')
    controls.start('open')
    await controls.start(
      wait(waitTime ? waitTime : randomFloat(minTime, maxTime))
    )
    sequence()
  }

  return sequence
}

const getFakkedEyeFlicker = () => {
  const len = randomInt(10, 16)
  let values = [0]
  for (let i = 0; i < len; i++) {
    values.push(randomFloat(-4, 4))
  }
  values.push(0)
  return values
}

export const StartCharacterEyes = () => {
  const controls = useAnimation()
  const sequence = getSequence(controls)
  useEffect(() => {
    sequence(1.5)
  }, [controls])

  return (
    <motion.svg
      width='66'
      height='42'
      viewBox='0 0 66 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ position: 'absolute', top: '33px', left: '19px' }}
      animate={controls}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.1247 22.1506C24.4038 24.7334 23.0413 26.9989 21.0814 27.2107C19.1216 27.4225 17.3066 25.5003 17.0275 22.9174C16.7484 20.3346 18.1109 18.0691 20.0708 17.8573C21.4145 17.7121 22.6901 18.57 23.4493 19.9562L22.4791 21.4859L24.0697 21.7577C24.0919 21.8867 24.1103 22.0178 24.1247 22.1506Z'
        fill='black'
      />
      <mask
        id='mask0'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='11'
        y='7'
        width='17'
        height='24'
      >
        <path
          d='M27.0479 17.7667C27.4085 21.1035 26.7472 24.1685 25.4621 26.419C24.1759 28.6715 22.3394 30.0079 20.3259 30.2255C18.3124 30.4431 16.2329 29.5298 14.4952 27.6041C12.759 25.68 11.4583 22.827 11.0977 19.4902C10.7372 16.1534 11.3985 13.0884 12.6835 10.8379C13.9697 8.58541 15.8062 7.24901 17.8197 7.03151C19.8332 6.81391 21.9128 7.72711 23.6505 9.65291C25.3866 11.5769 26.6874 14.4299 27.0479 17.7667Z'
          fill='#05FF00'
        />
      </mask>
      <g mask='url(#mask0)'>
        <motion.path
          d='M27.1439 16.2604C21.3221 12.9658 13.9565 17.7387 11.0015 20.5369L-6 7.5L-7 -8.5L9.5 -21L35.5 -12L27.1439 16.2604Z'
          fill='white'
          stroke='black'
          strokeWidth='3.04294'
          variants={getVariants(17)}
        />
      </g>
      <path
        d='M27.0479 17.7667C27.4085 21.1035 26.7472 24.1685 25.4621 26.419C24.1759 28.6715 22.3394 30.0079 20.3259 30.2255C18.3124 30.4431 16.2329 29.5298 14.4952 27.6041C12.759 25.68 11.4583 22.827 11.0977 19.4902C10.7372 16.1534 11.3985 13.0884 12.6835 10.8379C13.9697 8.58541 15.8062 7.24901 17.8197 7.03151C19.8332 6.81391 21.9128 7.72711 23.6505 9.65291C25.3866 11.5769 26.6874 14.4299 27.0479 17.7667Z'
        stroke='black'
        strokeWidth='3.04294'
        strokeLinecap='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.5848 26.4536C49.2069 29.1201 47.4281 31.1096 45.6116 30.8971C43.7951 30.6847 42.6289 28.3508 43.0067 25.6842C43.3845 23.0177 45.1634 21.0282 46.9798 21.2407C48.2252 21.3863 49.165 22.5292 49.511 24.0928L48.2648 25.4252L49.6309 26.0452C49.6195 26.1803 49.6042 26.3165 49.5848 26.4536Z'
        fill='black'
      />
      <mask
        id='mask1'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='39'
        y='9'
        width='17'
        height='27'
      >
        <path
          d='M39.0994 21.1953C38.7324 24.9879 39.4061 28.472 40.7126 31.0268C42.027 33.5971 43.8671 35.0476 45.8067 35.2816C47.7462 35.5156 49.8214 34.5375 51.5972 32.34C53.3622 30.1559 54.6869 26.9129 55.0539 23.1202C55.421 19.3276 54.7472 15.8434 53.4408 13.2887C52.1264 10.7184 50.2862 9.2679 48.3467 9.0339C46.4072 8.7998 44.332 9.778 42.5562 11.9754C40.7912 14.1596 39.4664 17.4026 39.0994 21.1953Z'
          fill='#00FF19'
        />
      </mask>
      <g mask='url(#mask1)'>
        <motion.path
          d='M39.0056 19.5142C44.8274 15.8355 52.193 21.1649 55.148 24.2894L78 5L73.5 -28.5L30 -30.5V-1L39.0056 19.5142Z'
          fill='white'
          stroke='black'
          strokeWidth='3.04294'
          variants={getVariants(17)}
        />
      </g>
      <path
        d='M39.0994 21.1953C38.7324 24.9879 39.4061 28.472 40.7126 31.0268C42.027 33.5971 43.8671 35.0476 45.8067 35.2816C47.7462 35.5156 49.8214 34.5375 51.5972 32.34C53.3622 30.1559 54.6869 26.9129 55.0539 23.1202C55.421 19.3276 54.7472 15.8434 53.4408 13.2887C52.1264 10.7184 50.2862 9.2679 48.3467 9.0339C46.4072 8.7998 44.332 9.778 42.5562 11.9754C40.7912 14.1596 39.4664 17.4026 39.0994 21.1953Z'
        stroke='black'
        strokeWidth='3.04294'
        strokeLinecap='round'
      />
    </motion.svg>
  )
}

export const CharacterConfidentEyes = () => {
  const controls = useAnimation()
  const sequence = getSequence(controls, 0.3, 3)
  useEffect(() => {
    sequence(0.8)
  }, [controls])

  return (
    <motion.svg
      width='76'
      height='48'
      viewBox='0 0 76 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ position: 'absolute', top: '45px', left: '57px' }}
      animate={controls}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M57.0774 28.5325C56.8997 31.5886 55.0714 34.0091 52.9938 33.9389C50.9163 33.8687 49.3761 31.3345 49.5538 28.2785C49.7315 25.2224 51.5598 22.8019 53.6373 22.8721C55.0618 22.9202 56.2336 24.1266 56.7723 25.8653L55.4856 27.4915L57.0913 28.0656C57.0911 28.2197 57.0865 28.3754 57.0774 28.5325Z'
        fill='black'
      />
      <mask
        id='mask0Confident'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='42'
        y='7'
        width='23'
        height='34'
      >
        <ellipse
          rx='10.8623'
          ry='16.7161'
          transform='matrix(-0.9993 -0.0373963 -0.0138015 0.999905 53.8332 23.9017)'
          fill='#00FF19'
        />
      </mask>
      <g mask='url(#mask0Confident)'>
        <motion.path
          d='M44.5893 24.2449C50.8388 19.5315 59.6823 24.877 63.3229 28.139L77.9998 13.4999L79.4999 -39.5L31 -41.5001L32.9999 7.49988L44.5893 24.2449Z'
          fill='white'
          stroke='black'
          strokeWidth='3'
          variants={getVariants(17)}
        />
      </g>
      <path
        d='M44.9183 23.5681C44.859 27.8637 45.941 31.7055 47.6358 34.4367C49.3423 37.1868 51.5057 38.5958 53.6293 38.6752C55.753 38.7547 57.953 37.509 59.7336 34.8894C61.5021 32.2878 62.6889 28.531 62.7482 24.2353C62.8075 19.9396 61.7255 16.0979 60.0307 13.3667C58.3242 10.6165 56.1608 9.2076 54.0372 9.12813C51.9135 9.04866 49.7135 10.2943 47.9329 12.914C46.1644 15.5156 44.9776 19.2724 44.9183 23.5681Z'
        stroke='black'
        strokeWidth='3.88228'
        strokeLinecap='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.7317 26.5501C28.2903 29.4496 26.9595 32.1438 24.7594 32.5676C22.5592 32.9914 20.3229 30.9845 19.7643 28.0849C19.2058 25.1854 20.5365 22.4912 22.7367 22.0674C24.2451 21.7768 25.7706 22.6289 26.7607 24.1279L25.8053 25.9517L27.6325 26.1102C27.6698 26.2543 27.703 26.401 27.7317 26.5501Z'
        fill='black'
      />
      <mask
        id='mask1Confident'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='8'
        y='6'
        width='28'
        height='34'
      >
        <ellipse
          cx='21.6785'
          cy='23.0348'
          rx='10.8469'
          ry='14.9876'
          transform='rotate(-10.9037 21.6785 23.0348)'
          fill='#05FF00'
        />
      </mask>
      <g mask='url(#mask1Confident)'>
        <motion.path
          d='M30.6154 23.9118C24.4752 19.4274 15.5324 23.8483 11.8285 26.6193L-4.50024 5.99988L-3.00012 -37L39.4999 -36.0001L39.4999 0.499949L30.6154 23.9118Z'
          fill='white'
          stroke='black'
          strokeWidth='3'
          variants={getVariants(17)}
        />
      </g>
      <path
        d='M30.4235 21.3502C31.1376 25.0572 30.6788 28.5452 29.4552 31.1704C28.2304 33.7983 26.3234 35.4264 24.1464 35.8457C21.9693 36.2651 19.594 35.4619 17.4806 33.4772C15.3693 31.4944 13.6476 28.4264 12.9335 24.7194C12.2194 21.0124 12.6782 17.5244 13.9018 14.8991C15.1266 12.2713 17.0336 10.6432 19.2106 10.2238C21.3877 9.80445 23.763 10.6076 25.8764 12.5924C27.9877 14.5752 29.7094 17.6432 30.4235 21.3502Z'
        stroke='black'
        strokeWidth='3.88228'
        strokeLinecap='round'
      />
    </motion.svg>
  )
}

export const CharacterConfusedEyes = () => {
  const controls = useAnimation()
  const sequence = getSequence(controls, 0.3, 3)
  useEffect(() => {
    sequence(1.7)
  }, [controls])

  return (
    <motion.svg
      width='110'
      height='70'
      viewBox='0 0 110 70'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ position: 'absolute', top: '67px', left: '82px' }}
      animate={controls}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41.0997 30.95C41.927 35.2446 39.9559 39.2349 36.6973 39.8627C33.4386 40.4904 30.1262 37.5179 29.2989 33.2233C28.4716 28.9287 30.4427 24.9383 33.7013 24.3106C35.9356 23.8802 38.195 25.1422 39.6614 27.3624L38.2464 30.0636L40.9527 30.2985C41.008 30.5119 41.0571 30.7292 41.0997 30.95Z'
        fill='black'
      />
      <mask
        id='mask0Confused'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='11'
        y='9'
        width='41'
        height='50'
      >
        <ellipse
          cx='31.2597'
          cy='33.9923'
          rx='16.0656'
          ry='22.1986'
          transform='rotate(-10.9037 31.2597 33.9923)'
          fill='#14FF00'
        />
      </mask>
      <g mask='url(#mask0Confused)'>
        <motion.path
          d='M43.4077 26.2611C34.3134 19.6191 21.7316 25.6248 16.2457 29.729L10.5 35L2.49996 -7.49991L10.5 -50.9999L54.9999 -54.9998L54.9999 37.5L43.4077 26.2611Z'
          fill='white'
          stroke='black'
          strokeWidth='3'
          variants={getVariants(32)}
        />
      </g>
      <path
        d='M45.1292 31.3208C46.22 36.983 45.5287 42.3518 43.6245 46.437C41.7191 50.5249 38.6837 53.1923 35.0916 53.8843C31.4995 54.5762 27.6903 53.2273 24.4027 50.1398C21.1172 47.0543 18.4809 42.3266 17.3902 36.6643C16.2994 31.002 16.9907 25.6333 18.8949 21.5481C20.8003 17.4602 23.8357 14.7928 27.4278 14.1008C31.0199 13.4088 34.8291 14.7577 38.1167 17.8452C41.4022 20.9308 44.0384 25.6585 45.1292 31.3208Z'
        stroke='black'
        strokeWidth='3.88228'
        strokeLinecap='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M81.9896 42.4331C84.1369 46.4341 83.3418 51.0194 80.2136 52.6747C77.0855 54.33 72.8089 52.4285 70.6616 48.4275C68.5143 44.4265 69.3094 39.8411 72.4376 38.1858C74.5823 37.0509 77.2668 37.588 79.4349 39.3274L78.8144 42.457L81.642 41.8329C81.7632 42.0273 81.8792 42.2274 81.9896 42.4331Z'
        fill='black'
      />
      <mask
        id='mask1Confused'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='63'
        y='10'
        width='33'
        height='50'
      >
        <ellipse
          rx='16.0885'
          ry='24.7587'
          transform='matrix(-0.9993 -0.0373963 -0.0138015 0.999905 79.3844 34.9957)'
          fill='#05FF00'
        />
      </mask>
      <g mask='url(#mask1Confused)'>
        <motion.path
          d='M65.7455 37.7291C70.2186 27.033 86.1529 28.2759 93.2455 29.7291L107.5 34.0002L111 -52.5L65.7455 -55L59.4998 4.00013L61.9998 45.0001L65.7455 37.7291Z'
          fill='white'
          stroke='black'
          strokeWidth='3.5'
          variants={getVariants(31)}
        />
      </g>
      <path
        d='M65.2469 34.4667C65.157 40.983 66.7957 46.8571 69.4155 51.0789C72.0471 55.3197 75.5037 57.6776 79.0695 57.8111C82.6353 57.9445 86.1533 55.8476 88.8992 51.8081C91.6328 47.7865 93.4319 42.0411 93.5219 35.5248C93.6118 29.0084 91.973 23.1344 89.3532 18.9125C86.7216 14.6717 83.2651 12.3138 79.6993 12.1804C76.1335 12.0469 72.6155 14.1438 69.8696 18.1834C67.136 22.2049 65.3368 27.9503 65.2469 34.4667Z'
        stroke='black'
        strokeWidth='3.88228'
        strokeLinecap='round'
      />
    </motion.svg>
  )
}

export const CharacterHappyEyes = () => {
  const controls = useAnimation()
  const sequence = getSequence(controls, 0.3, 3)
  useEffect(() => {
    sequence(0.6)
  }, [controls])

  return (
    <motion.svg
      width='110'
      height='86'
      viewBox='0 0 110 86'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ position: 'absolute', top: '37px', left: '61px' }}
      animate={controls}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.6827 50.4889C45.3773 54.0946 43.7224 57.4449 40.9864 57.972C38.2505 58.499 35.4695 56.0032 34.7749 52.3975C34.0803 48.7918 35.7351 45.4415 38.4711 44.9145C40.3469 44.5531 42.2439 45.6127 43.4752 47.4768L42.2871 49.7447L44.5593 49.9419C44.6057 50.1211 44.647 50.3036 44.6827 50.4889Z'
        fill='black'
      />
      <mask
        id='mask0Happy'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='19'
        y='19'
        width='37'
        height='54'
      >
        <ellipse
          cx='37.1556'
          cy='46.1175'
          rx='13.4886'
          ry='24.6947'
          transform='rotate(-10.9037 37.1556 46.1175)'
          fill='#00FF19'
        />
      </mask>
      <g mask='url(#mask0Happy)'>
        <motion.path
          d='M44.8806 30.7904C30.8571 24.8157 26.4493 46.6962 26.783 57.8515L10.1504 57.8515L7.15088 -46L54.1504 -44.5L56.1504 38L44.8806 30.7904Z'
          fill='white'
          stroke='black'
          strokeWidth='3.41'
          variants={getVariants(42)}
        />
      </g>
      <ellipse
        cx='37.1556'
        cy='46.1175'
        rx='13.4886'
        ry='24.6947'
        transform='rotate(-10.9037 37.1556 46.1175)'
        stroke='black'
        strokeWidth='4.82779'
        strokeLinecap='round'
        strokeLinejoin='bevel'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M75.9371 41.8217C76.2574 46.033 74.5785 49.5496 72.1872 49.6763C69.7958 49.8031 67.5976 46.4919 67.2773 42.2807C66.957 38.0694 68.636 34.5528 71.0273 34.426C72.6668 34.3392 74.2156 35.8683 75.1306 38.1956L73.9358 40.5646L75.8731 41.1797C75.8992 41.3911 75.9206 41.6052 75.9371 41.8217Z'
        fill='black'
      />
      <mask
        id='mask1Happy'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='59'
        y='17'
        width='26'
        height='47'
      >
        <ellipse
          rx='12.5156'
          ry='23.1002'
          transform='matrix(-0.99882 0.0485622 0.112581 0.993643 72.1613 40.0955)'
          fill='#00FF0A'
        />
      </mask>
      <g mask='url(#mask1Happy)'>
        <motion.path
          d='M61.7324 31.7072C70.0459 27.1073 78.4467 36.815 81.608 42.2439L92.6504 57.5V-32L52.6504 -38L56.1504 37L61.7324 31.7072Z'
          fill='white'
          stroke='black'
          strokeWidth='3.41'
          variants={getVariants(30)}
        />
      </g>
      <path
        d='M62.0715 40.5861C62.7534 46.6048 64.6911 51.8699 67.1512 55.5101C69.6734 59.2421 72.3425 60.7548 74.4902 60.6503C76.6379 60.5459 78.9368 58.7918 80.5903 54.8567C82.2031 51.0185 82.933 45.6236 82.2511 39.605C81.5692 33.5863 79.6315 28.3212 77.1714 24.681C74.6492 20.949 71.9802 19.4363 69.8324 19.5408C67.6847 19.6452 65.3858 21.3993 63.7323 25.3344C62.1195 29.1726 61.3896 34.5675 62.0715 40.5861Z'
        stroke='black'
        strokeWidth='4.82779'
        strokeLinecap='round'
      />
    </motion.svg>

    // <motion.svg
    //   width='110'
    //   height='70'
    //   viewBox='0 0 110 70'
    //   fill='none'
    //   xmlns='http://www.w3.org/2000/svg'
    //   style={{ position: 'absolute', top: '67px', left: '82px' }}
    //   animate={controls}
    // >
    //   <path
    //     fillRule='evenodd'
    //     clipRule='evenodd'
    //     d='M41.0997 30.95C41.927 35.2446 39.9559 39.2349 36.6973 39.8627C33.4386 40.4904 30.1262 37.5179 29.2989 33.2233C28.4716 28.9287 30.4427 24.9383 33.7013 24.3106C35.9356 23.8802 38.195 25.1422 39.6614 27.3624L38.2464 30.0636L40.9527 30.2985C41.008 30.5119 41.0571 30.7292 41.0997 30.95Z'
    //     fill='black'
    //   />
    //   <mask
    //     id='mask0Confused'
    //     mask-type='alpha'
    //     maskUnits='userSpaceOnUse'
    //     x='11'
    //     y='9'
    //     width='41'
    //     height='50'
    //   >
    //     <ellipse
    //       cx='31.2597'
    //       cy='33.9923'
    //       rx='16.0656'
    //       ry='22.1986'
    //       transform='rotate(-10.9037 31.2597 33.9923)'
    //       fill='#14FF00'
    //     />
    //   </mask>
    //   <g mask='url(#mask0Confused)'>
    //     <motion.path
    //       d='M43.4077 26.2611C34.3134 19.6191 21.7316 25.6248 16.2457 29.729L10.5 35L2.49996 -7.49991L10.5 -50.9999L54.9999 -54.9998L54.9999 37.5L43.4077 26.2611Z'
    //       fill='white'
    //       stroke='black'
    //       strokeWidth='3'
    //       variants={getVariants(32)}
    //     />
    //   </g>
    //   <path
    //     d='M45.1292 31.3208C46.22 36.983 45.5287 42.3518 43.6245 46.437C41.7191 50.5249 38.6837 53.1923 35.0916 53.8843C31.4995 54.5762 27.6903 53.2273 24.4027 50.1398C21.1172 47.0543 18.4809 42.3266 17.3902 36.6643C16.2994 31.002 16.9907 25.6333 18.8949 21.5481C20.8003 17.4602 23.8357 14.7928 27.4278 14.1008C31.0199 13.4088 34.8291 14.7577 38.1167 17.8452C41.4022 20.9308 44.0384 25.6585 45.1292 31.3208Z'
    //     stroke='black'
    //     strokeWidth='3.88228'
    //     strokeLinecap='round'
    //   />
    //   <path
    //     fillRule='evenodd'
    //     clipRule='evenodd'
    //     d='M81.9896 42.4331C84.1369 46.4341 83.3418 51.0194 80.2136 52.6747C77.0855 54.33 72.8089 52.4285 70.6616 48.4275C68.5143 44.4265 69.3094 39.8411 72.4376 38.1858C74.5823 37.0509 77.2668 37.588 79.4349 39.3274L78.8144 42.457L81.642 41.8329C81.7632 42.0273 81.8792 42.2274 81.9896 42.4331Z'
    //     fill='black'
    //   />
    //   <mask
    //     id='mask1Confused'
    //     mask-type='alpha'
    //     maskUnits='userSpaceOnUse'
    //     x='63'
    //     y='10'
    //     width='33'
    //     height='50'
    //   >
    //     <ellipse
    //       rx='16.0885'
    //       ry='24.7587'
    //       transform='matrix(-0.9993 -0.0373963 -0.0138015 0.999905 79.3844 34.9957)'
    //       fill='#05FF00'
    //     />
    //   </mask>
    //   <g mask='url(#mask1Confused)'>
    //     <motion.path
    //       d='M65.7455 37.7291C70.2186 27.033 86.1529 28.2759 93.2455 29.7291L107.5 34.0002L111 -52.5L65.7455 -55L59.4998 4.00013L61.9998 45.0001L65.7455 37.7291Z'
    //       fill='white'
    //       stroke='black'
    //       strokeWidth='3.5'
    //       variants={getVariants(31)}
    //     />
    //   </g>
    //   <path
    //     d='M65.2469 34.4667C65.157 40.983 66.7957 46.8571 69.4155 51.0789C72.0471 55.3197 75.5037 57.6776 79.0695 57.8111C82.6353 57.9445 86.1533 55.8476 88.8992 51.8081C91.6328 47.7865 93.4319 42.0411 93.5219 35.5248C93.6118 29.0084 91.973 23.1344 89.3532 18.9125C86.7216 14.6717 83.2651 12.3138 79.6993 12.1804C76.1335 12.0469 72.6155 14.1438 69.8696 18.1834C67.136 22.2049 65.3368 27.9503 65.2469 34.4667Z'
    //     stroke='black'
    //     strokeWidth='3.88228'
    //     strokeLinecap='round'
    //   />
    // </motion.svg>
  )
}

export const CharacterFakkedEyes = () => {
  const [leftValues, setLeftValues] = useState(getFakkedEyeFlicker())
  const [rightValues, setRightValues] = useState(getFakkedEyeFlicker())
  // const controls = useAnimation()
  // const sequence = getSequence(controls, 0.3, 3)
  // useEffect(() => {
  //   sequence(1.7)
  // }, [controls])

  return (
    <motion.svg
      width='180'
      height='80'
      viewBox='0 0 180 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ position: 'absolute', top: '23px', left: '54px', zIndex: 4 }}
      // animate={controls}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M128.047 33.7259C131.13 43.5957 130.3 51.7058 126.192 51.8403C122.084 51.9748 116.255 44.0827 113.171 34.2128C110.088 24.3429 110.918 16.2328 115.026 16.0984C117.843 16.0062 121.468 19.687 124.464 25.1915L123.792 30.6504L127.55 32.2202C127.723 32.7163 127.889 33.2185 128.047 33.7259Z'
        fill='black'
      />
      <mask
        id='mask0Fakked'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='89'
        y='12'
        width='73'
        height='56'
      >
        <ellipse
          rx='35.6065'
          ry='27.8756'
          transform='matrix(-0.999746 0.0225274 -0.108651 0.99408 125.324 39.7781)'
          fill='#33FF00'
        />
      </mask>
      <g mask='url(#mask0Fakked)'>
        <motion.path
          d='M79.5509 18.9998C129.551 10.9998 155.051 27.4998 173.051 47.9998L177.051 -75.0002L69.551 -84.5L79.5509 18.9998Z'
          fill='white'
          stroke='black'
          strokeWidth='4'
          animate={{ y: leftValues }}
          transition={{ ease: 'linear', duration: 1.2, repeat: Infinity }}
        />
      </g>
      <path
        d='M92.2353 40.5237C90.7716 53.9158 103.687 65.4193 122.568 64.9939C141.449 64.5684 156.948 52.4246 158.412 39.0325C159.876 25.6404 146.961 14.1368 128.08 14.5623C109.199 14.9877 93.699 27.1316 92.2353 40.5237Z'
        stroke='black'
        strokeWidth='5.01929'
        strokeLinecap='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M43.7256 44.8888C44.9672 47.7281 44.5496 50.1103 42.793 50.2096C41.0364 50.3088 38.6059 48.0876 37.3644 45.2482C36.1229 42.4089 36.5404 40.0267 38.297 39.9274C39.5014 39.8594 41.0225 40.8821 42.26 42.447L41.9311 44.0521L43.525 44.456C43.5948 44.5985 43.6618 44.7428 43.7256 44.8888Z'
        fill='black'
      />
      <mask
        id='mask1Fakked'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='13'
        y='33'
        width='60'
        height='31'
      >
        <path
          d='M13.6509 49.5864C11.8968 57.8704 23.6781 64.0078 39.9652 63.2948C56.2524 62.5817 70.8777 55.2882 72.6319 47.0042C74.386 38.7202 62.6047 32.5828 46.3176 33.2958C30.0305 34.0089 15.4051 41.3024 13.6509 49.5864Z'
          fill='#24FF00'
        />
      </mask>
      <g mask='url(#mask1Fakked)'>
        <motion.path
          d='M75.5505 37.0015C64.998 36.6296 20.5509 34 19.0509 66C-4.94922 59.5 -4.94922 -9.49951 -4.94922 -9.49951L81.0508 -14.9995L75.5505 37.0015Z'
          fill='white'
          stroke='black'
          strokeWidth='4'
          animate={{ y: rightValues }}
          transition={{ ease: 'linear', duration: 1, repeat: Infinity }}
        />
      </g>
      <path
        d='M39.8555 60.7878C32.0012 61.1316 25.4577 59.8059 21.2021 57.589C16.9119 55.354 15.5662 52.6567 16.1061 50.1065C16.7382 47.1219 19.9375 43.6991 25.6544 40.8481C31.22 38.0726 38.5986 36.1461 46.4274 35.8033C54.2817 35.4595 60.8251 36.7852 65.0807 39.0021C69.3709 41.2371 70.7167 43.9345 70.1767 46.4846C69.5447 49.4692 66.3454 52.892 60.6284 55.743C55.0628 58.5185 47.6842 60.445 39.8555 60.7878Z'
        stroke='black'
        strokeWidth='5.01929'
        strokeLinecap='round'
      />
    </motion.svg>

    // <motion.svg
    //   width='110'
    //   height='70'
    //   viewBox='0 0 110 70'
    //   fill='none'
    //   xmlns='http://www.w3.org/2000/svg'
    //   style={{ position: 'absolute', top: '67px', left: '82px' }}
    //   animate={controls}
    // >
    //   <path
    //     fillRule='evenodd'
    //     clipRule='evenodd'
    //     d='M41.0997 30.95C41.927 35.2446 39.9559 39.2349 36.6973 39.8627C33.4386 40.4904 30.1262 37.5179 29.2989 33.2233C28.4716 28.9287 30.4427 24.9383 33.7013 24.3106C35.9356 23.8802 38.195 25.1422 39.6614 27.3624L38.2464 30.0636L40.9527 30.2985C41.008 30.5119 41.0571 30.7292 41.0997 30.95Z'
    //     fill='black'
    //   />
    //   <mask
    //     id='mask0Confused'
    //     mask-type='alpha'
    //     maskUnits='userSpaceOnUse'
    //     x='11'
    //     y='9'
    //     width='41'
    //     height='50'
    //   >
    //     <ellipse
    //       cx='31.2597'
    //       cy='33.9923'
    //       rx='16.0656'
    //       ry='22.1986'
    //       transform='rotate(-10.9037 31.2597 33.9923)'
    //       fill='#14FF00'
    //     />
    //   </mask>
    //   <g mask='url(#mask0Confused)'>
    //     <motion.path
    //       d='M43.4077 26.2611C34.3134 19.6191 21.7316 25.6248 16.2457 29.729L10.5 35L2.49996 -7.49991L10.5 -50.9999L54.9999 -54.9998L54.9999 37.5L43.4077 26.2611Z'
    //       fill='white'
    //       stroke='black'
    //       strokeWidth='3'
    //       variants={getVariants(32)}
    //     />
    //   </g>
    //   <path
    //     d='M45.1292 31.3208C46.22 36.983 45.5287 42.3518 43.6245 46.437C41.7191 50.5249 38.6837 53.1923 35.0916 53.8843C31.4995 54.5762 27.6903 53.2273 24.4027 50.1398C21.1172 47.0543 18.4809 42.3266 17.3902 36.6643C16.2994 31.002 16.9907 25.6333 18.8949 21.5481C20.8003 17.4602 23.8357 14.7928 27.4278 14.1008C31.0199 13.4088 34.8291 14.7577 38.1167 17.8452C41.4022 20.9308 44.0384 25.6585 45.1292 31.3208Z'
    //     stroke='black'
    //     strokeWidth='3.88228'
    //     strokeLinecap='round'
    //   />
    //   <path
    //     fillRule='evenodd'
    //     clipRule='evenodd'
    //     d='M81.9896 42.4331C84.1369 46.4341 83.3418 51.0194 80.2136 52.6747C77.0855 54.33 72.8089 52.4285 70.6616 48.4275C68.5143 44.4265 69.3094 39.8411 72.4376 38.1858C74.5823 37.0509 77.2668 37.588 79.4349 39.3274L78.8144 42.457L81.642 41.8329C81.7632 42.0273 81.8792 42.2274 81.9896 42.4331Z'
    //     fill='black'
    //   />
    //   <mask
    //     id='mask1Confused'
    //     mask-type='alpha'
    //     maskUnits='userSpaceOnUse'
    //     x='63'
    //     y='10'
    //     width='33'
    //     height='50'
    //   >
    //     <ellipse
    //       rx='16.0885'
    //       ry='24.7587'
    //       transform='matrix(-0.9993 -0.0373963 -0.0138015 0.999905 79.3844 34.9957)'
    //       fill='#05FF00'
    //     />
    //   </mask>
    //   <g mask='url(#mask1Confused)'>
    //     <motion.path
    //       d='M65.7455 37.7291C70.2186 27.033 86.1529 28.2759 93.2455 29.7291L107.5 34.0002L111 -52.5L65.7455 -55L59.4998 4.00013L61.9998 45.0001L65.7455 37.7291Z'
    //       fill='white'
    //       stroke='black'
    //       strokeWidth='3.5'
    //       variants={getVariants(31)}
    //     />
    //   </g>
    //   <path
    //     d='M65.2469 34.4667C65.157 40.983 66.7957 46.8571 69.4155 51.0789C72.0471 55.3197 75.5037 57.6776 79.0695 57.8111C82.6353 57.9445 86.1533 55.8476 88.8992 51.8081C91.6328 47.7865 93.4319 42.0411 93.5219 35.5248C93.6118 29.0084 91.973 23.1344 89.3532 18.9125C86.7216 14.6717 83.2651 12.3138 79.6993 12.1804C76.1335 12.0469 72.6155 14.1438 69.8696 18.1834C67.136 22.2049 65.3368 27.9503 65.2469 34.4667Z'
    //     stroke='black'
    //     strokeWidth='3.88228'
    //     strokeLinecap='round'
    //   />
    // </motion.svg>
  )
}

export const InfinitySymbol = () => {
  return (
    <svg
      width='50px'
      viewBox='0 0 140 62'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ margin: '20px auto 0', display: 'block' }}
    >
      <motion.path
        animate={{
          pathLength: [0, 1, 1],
          pathOffset: [0, 0, 1],
        }}
        transition={{
          duration: 2,
          ease: 'linear',
          repeat: Infinity,
        }}
        d='M70.3455 30.4521C59.7055 19.7552 50.5855 7.00128 37.1818 7.00128C23.7782 7.00128 13 17.6981 13 31.0006C13 44.3031 23.7782 55 37.1818 55C50.5855 55 59.9818 42.5203 72.28 30.4521'
        stroke='black'
        strokeWidth='10'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <motion.path
        style={{ scaleX: -1, x: 56.6 }}
        animate={{
          pathLength: [0, 1, 1],
          pathOffset: [0, 0, 1],
        }}
        transition={{
          duration: 2,
          ease: 'linear',
          repeat: Infinity,
          delay: 1,
        }}
        d='M70.3455 30.4521C59.7055 19.7552 50.5855 7.00128 37.1818 7.00128C23.7782 7.00128 13 17.6981 13 31.0006C13 44.3031 23.7782 55 37.1818 55C50.5855 55 59.9818 42.5203 72.28 30.4521'
        stroke='black'
        strokeWidth='10'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
