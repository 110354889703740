import { useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import fileDownload from 'js-file-download'
import useSize from '@react-hook/size'
import './style.js'
import StyledResult from './style'
import { RoundButton } from '../../components/RoundButton'
import { Button } from '../../components/Button'
import { updateRhymes, updateId } from '../../actions/appActions.js'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../App.js'
import { useParams } from 'react-router'
import { capitalize, trackWord } from '../../utilities/helpers.js'
import { TITLE_SUFFIX } from '../../utilities/constants.js'
import { getGeneratedRhyme } from '../../api/api.js'

import { ResultBackground } from '../../components/animations/ResultBackground'
import { ResultStepOne } from '../../components/animations/ResultStepOne'
import { ResultStepTwo } from '../../components/animations/ResultStepTwo'
import { ResultStepThree } from '../../components/animations/ResultStepThree'
import { ResultStepFour } from '../../components/animations/ResultStepFour/index.js'
import { ResultLastStep } from '../../components/animations/ResultLastStep/index.js'

import iconRefresh from '../../img/result/iconRefresh.svg'
import iconCopy from '../../img/result/iconCopy.svg'
import iconShare from '../../img/result/iconShare.svg'
import iconDownload from '../../img/result/iconDownload.svg'

import { InfinitySymbol } from '../../components/animations/Svg/index.js'

const Result = () => {
  const { word } = useParams()
  const [{ rhymes, id }, dispatch] = useContext(AppContext)
  const [generating, setGenerating] = useState(true)

  const [atStep, setAtStep] = useState(1)
  const [reRun, setReRun] = useState(false)

  const bodyRef = useRef(null)
  const [, bodyHeight] = useSize(bodyRef)

  const niceWord = capitalize(word)

  /**
   * If generating
   */
  useEffect(() => {
    const run = async () => {
      if (generating) {
        // ID for share functions...
        let { _id, rhyme } = await getGeneratedRhyme(word)
        await dispatch(updateRhymes(rhyme))
        await dispatch(updateId(_id))
        // To test load-delay locally
        // await new Promise(r => setTimeout(r, 6000))
        setGenerating(false)
        trackWord(niceWord)
      }
    }
    run()
  }, [generating])

  // First step
  useEffect(() => {
    let firstTimeout
    if (atStep === 1) {
      firstTimeout = setTimeout(() => {
        setAtStep(2)
      }, 1600)
    }

    // Cleanup timeout
    return () => {
      if (firstTimeout) clearTimeout(firstTimeout)
    }
  }, [atStep])

  const devStopAt = 99
  const [stepTwoHasTimedOut, setStepTwoHasTimedOut] = useState(false)

  // Then step through
  useEffect(() => {
    let time = 3000
    if (atStep === 2) time = 2200
    if (atStep === 3) time = 2400
    if (atStep === 4) time = 2800
    if (atStep === 5) time = 1900

    let stepTimeout

    if (atStep === 2) {
      if (generating) {
        stepTimeout = setTimeout(() => {
          setStepTwoHasTimedOut(true)
          if (!generating) setAtStep(atStep + 1)
        }, time)
      } else {
        if (stepTwoHasTimedOut){
          setAtStep(atStep + 1)
        }else{
          stepTimeout = setTimeout(() => {
            setStepTwoHasTimedOut(true)
            if (!generating) setAtStep(atStep + 1)
          }, time)
        }
      }
    }

    if (atStep >= 3 && atStep < 6 && atStep < devStopAt) {
      stepTimeout = setTimeout(() => {
        setAtStep(atStep + 1)
      }, time)
    }

    // Cleanup timeout
    return () => clearTimeout(stepTimeout)
  }, [atStep, generating])

  let bodyDelay = 0.5
  if (atStep === 0) bodyDelay = 0.35
  if (atStep === 5) bodyDelay = 0.15

  const handleDownload = (url, filename) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          console.error('Network response was not ok')
        }
        return response.blob()
      })
      .then((myBlob) => {
        fileDownload(myBlob, filename)
      })
      .catch((error) => {
        console.error('Kunde inte ladda ner fil:', error)
      })
  }

  const handleShare = async (url) => {
    const shareData = {
      title: 'Rimmo',
      text: 'Rimmo - En AI-bot som skapar julklappsrim till dina julklappar!',
      url: url,
    }

    try {
      await navigator.share(shareData)
    } catch (err) {
      const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
      window.open(fbUrl, '_blank')
    }
  }

  return (
    <>
      <Helmet title={`Julklappsrim på "${niceWord}" - ${TITLE_SUFFIX}`} />
      <StyledResult
        key={'result'}
        initial={'initial'}
        animate={'in'}
        exit={'out'}
      >
        <motion.div
          style={{ position: 'absolute', left: '50%' }}
          animate={{ y: atStep === 0 ? 0 : bodyHeight }}
          transition={{ delay: atStep === 0 || reRun ? 0.1 : 0 }}
        >
          <ResultBackground
            atStep={atStep}
            atLastAnimationStep={atStep === 5}
            reRun={reRun}
          />
        </motion.div>

        <StyledResult.Body
          ref={bodyRef}
          initial={{ opacity: 0 }}
          animate={{
            opacity: atStep >= 2 || reRun ? 1 : 0,
            y: atStep === 5 ? '-100vh' : 0,
          }}
          transition={{
            duration: 0.3,
            delay: bodyDelay,
          }}
        >
          {atStep != 0 && <h1>{niceWord}</h1>}
          {atStep === 2 && <InfinitySymbol />}
          {rhymes &&
            rhymes.length > 0 &&
            rhymes.map((line, index) => {
              return index <= atStep - 3 ? (
                <motion.p
                  key={index}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                >
                  {line}
                </motion.p>
              ) : null
            })}

          {rhymes && rhymes.length > 0 && atStep >= 6 && (
            <StyledResult.Actions
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.2 }}
            >
              <RoundButton
                icon={iconRefresh}
                text={'Ett till!'}
                onClick={() => {
                  setStepTwoHasTimedOut(false)
                  setReRun(true)
                  setAtStep(1)
                  setGenerating(true)
                }}
              />
              <CopyToClipboard
                text={rhymes.join(' ')}
                onCopy={() => {
                  alert('Rim kopierat!')
                }}
              >
                <RoundButton icon={iconCopy} text={'Kopiera'} />
              </CopyToClipboard>
              <RoundButton
                icon={iconShare}
                text={'Dela'}
                onClick={() => {
                  handleShare(`https://www.rimmo.se/share/${id}`)
                }}
              />
              {/* <RoundButton
                icon={iconShare}
                text={'Dela'}
                href={`https://www.facebook.com/sharer/sharer.php?u=https://www.rimmo.se/share/${id}`}
                target={'_blank'}
              /> */}
              <StyledResult.Desktop>
                <RoundButton
                  icon={iconDownload}
                  text={'Ladda ner'}
                  onClick={() => {
                    handleDownload(
                      `https://rimmo.herokuapp.com/rhyme/${id}/image/square`,
                      'rimmo.jpg'
                    )
                  }}
                />
              </StyledResult.Desktop>
              <StyledResult.Mobile>
                <RoundButton
                  icon={iconDownload}
                  text={'Ladda ner'}
                  href={`https://rimmo.herokuapp.com/rhyme/${id}/image/square`}
                  target={'_blank'}
                />
              </StyledResult.Mobile>
            </StyledResult.Actions>
          )}
        </StyledResult.Body>

        <AnimatePresence exitBeforeEnter={atStep === 0}>
          {atStep === 1 && <ResultStepOne key={'stepOne'} />}
          {atStep === 2 && <ResultStepTwo key={'stepTwo'} />}
          {atStep === 3 && <ResultStepThree key={'stepThree'} />}
          {atStep === 4 && <ResultStepFour key={'stepFour'} />}
          {atStep === 5 && <ResultLastStep key={'lastStep'} />}

          {/* Below to give time for out animation */}
          {atStep === 0 && (
            <motion.div
              variants={{
                out: {
                  opacity: 0,
                  transition: {
                    duration: 0.4,
                  },
                },
              }}
            />
          )}
        </AnimatePresence>

        {rhymes && rhymes.length > 0 && atStep >= 6 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.6 }}
          >
            <Link to={'/'}>
              <Button
                text={'Tillbaka'}
                handleClick={() => {
                  setStepTwoHasTimedOut(false)
                  setReRun(false)
                  setAtStep(0)
                }}
              />
            </Link>
          </motion.div>
        )}
      </StyledResult>
    </>
  )
}

export default Result
