import styled from 'styled-components'
import { motion } from 'framer-motion'
import StyledSplash from '../Splash/style'
import StyledTextTitle from '../TextTitle/style'

const StyledResultLastStep = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;

  ${StyledSplash} {
    width: 320px;
    position: absolute;
    left: 50%;
    top: calc( 50% - 55px );
    transform: translateX(-50%);
  }

  ${StyledTextTitle} {
    top: 50%;
    transform: translateY(-20px) rotate(10deg);
  }
`

export default StyledResultLastStep
