import styled from 'styled-components'

const StyledInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;

  img {
    width: 100%;
  }

  > input {
    position: absolute;
    height: 40px;
    width: 100%;
    padding: 0 15px;
    background: transparent;
    border: none;
    text-align: center;
    font-size: 24px;
    box-sizing: border-box;
    font-family: 'Funkis A';

    &:focus {
      outline: none;
    }
  }
`

export default StyledInput