import { motion } from 'framer-motion'
import { characterWrapperVariants } from '../helpers/utils'
import happyRimmo from '../../../img/result/happy/happyRimmoNoEyesOneArm.svg'
import happyCord from '../../../img/result/happy/happyCord.svg'
import armFront from '../../../img/result/happy/happyArmFront.svg'
import armBack from '../../../img/result/happy/happyArmBack.svg'
import { CharacterHappyEyes } from '../Svg'

const CharacterHappy = (props) => {
  const armStyle = {
    position: 'absolute',
    top: '128px',
    left: '83px',
    originX: 0.4,
    originY: 0.21,
  }
  const armInitial = {
    rotate: 0,
    y: 20,
    x: -20,
  }
  const armAnimation = {
    rotate: 20,
    y: 0,
    x: 0,
  }
  const armTransition = {
    duration: 0.4,
    ease: 'backOut',
    delay: 0.5,
  }

  return (
    <motion.div
      variants={characterWrapperVariants}
      style={{
        position: 'absolute',
        top: 'calc(50% - 210px)',
        left: 'calc(50% - 114px)',
        originY: 1,
      }}
    >
      {/* Wrapper */}
      <motion.div
        style={{ originY: 0.8 }}
        // animate={{
        //   rotate: [5, -5, 2, -7, 5],
        // }}
        // transition={{
        //   duration: 4,
        //   ease: 'easeInOut',
        //   times: [0, 0.2, 0.5, 0.8, 1],
        //   loop: Infinity,
        // }}
      >
        <motion.img
          style={armStyle}
          initial={armInitial}
          animate={armAnimation}
          transition={armTransition}
          src={armBack}
          alt={''}
        />

        <motion.img
          style={{ position: 'relative' }}
          src={happyRimmo}
          alt={''}
        />

        <motion.img
          style={armStyle}
          initial={armInitial}
          animate={armAnimation}
          transition={armTransition}
          src={armFront}
          alt={''}
        />

        <CharacterHappyEyes />

        {/* Cord */}
        <motion.div
          style={{
            position: 'absolute',
            top: '-81px',
            left: '56px',
            originY: 0.82,
            originX: 0.49,
          }}
          initial={{ rotate: -80 }}
          animate={{
            rotate: -30,
          }}
          transition={{
            type: 'spring',
            duration: 2.5,
            bounce: 0.8,
            velocity: 0,
            delay: 0.42,
          }}
        >
          <img src={happyCord} alt={''} />
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export { CharacterHappy }
