import * as Sentry from "@sentry/react";

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const randomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const randomFloat = (min, max) => {
  return Math.random() * (max - min) + min
}

export const getRandomItemInArray = (array) => {
  return array[randomInt(0, array.length - 1)]
}

export const trackWord = async (word) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'page_view',
    page_title: `Generate - ${word}`,
    page_location: window.location.href,
    page_path: window.location.hash.replace('#', '')
  })
}

/**
 * Send errors to sentry
 * @param {*} error
 */
export const catchError = (error, data = {}) => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.captureException(error, {
      extra: {
        formData: JSON.stringify(data),
      },
    })
  }
}