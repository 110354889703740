import StyledResultBackground from './style'
import { motion } from 'framer-motion'

const ResultBackground = ({ atStep, atLastAnimationStep, reRun }) => {
  const arcVariants = {
    initial: { y: '-100vh' },
    in: {
      y: '0vh',
      transition: {
        type: 'spring',
        damping: 20,
        mass: 1,
        stiffness: 180,
        delay: 0.5,
      },
    },
    out: {
      y: '-100vh',
      transition: { ease: 'easeInOut', duration: 0.4, delay: 0.15 },
    },
  }

  let currentState = atStep >= 2 || reRun ? 'in' : 'out'
  if(atLastAnimationStep) currentState = 'out'

  return (
    <StyledResultBackground>
      <StyledResultBackground.ArcWrapper
        initial={'initial'}
        animate={currentState}
        variants={arcVariants}
      >
        <StyledResultBackground.Arc>
          <div />
        </StyledResultBackground.Arc>
      </StyledResultBackground.ArcWrapper>
    </StyledResultBackground>
  )
}

export { ResultBackground }
