import StyledFooter from './style'

const Footer = ({ atRoot }) => {
  return (
    <StyledFooter atRoot={atRoot}>
      <StyledFooter.Inner>
        <StyledFooter.Line>
          <div />
          <div />
          <div />
          <div />
          <div />
        </StyledFooter.Line>
        En dum AI-tjänst från digitalbyrån{' '}
        <a target={'_blank'} href={'https://raketwebbyra.se/aktuellt/lyckad-hackathon-dalig-ai-bot/'}>
          Raket
        </a>
      </StyledFooter.Inner>
    </StyledFooter>
  )
}

export { Footer }
