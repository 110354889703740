import { motion } from 'framer-motion'
import { characterWrapperVariants } from '../helpers/utils'
import confusedRimmo from '../../../img/result/confused/confusedRimmoNoHandNoEyes.svg'
import handFront from '../../../img/result/confused/confusedHandFront.svg'
import handBack from '../../../img/result/confused/confusedHandBack.svg'
import { CharacterConfusedEyes } from '../Svg'

const CharacterConfused = (props) => {
  const handStyle = {
    position: 'absolute',
    top: '180px',
    left: '123px',
    originX: 0.61,
    originY: 0.54,
  }
  const handAnimation = {
    rotate: [-30, -30, 0, 0, -10, -10, -30],
  }
  const handTransition = {
    duration: 4,
    ease: 'easeOut',
    times: [0, 0.3, 0.4, 0.5, 0.6, 0.90, 1],
    loop: Infinity,
  }
  return (
    <motion.div
      variants={characterWrapperVariants}
      style={{
        position: 'absolute',
        bottom: '-50px',
        left: 'calc(50% - 137px)',
        originY: 1,
      }}
    >
      {/* Wrapper */}
      <motion.div
        style={{ originY: 1 }}
        // animate={{
        //   rotate: [5, -5, 2, -7, 5],
        // }}
        // transition={{
        //   duration: 4,
        //   ease: 'easeInOut',
        //   times: [0, 0.2, 0.5, 0.8, 1],
        //   loop: Infinity,
        // }}
      >
        <motion.img
          style={handStyle}
          animate={handAnimation}
          transition={handTransition}
          src={handBack}
          alt={''}
        />
        <motion.img
          style={{ position: 'relative' }}
          src={confusedRimmo}
          alt={''}
        />
        <CharacterConfusedEyes />
        <motion.img
          style={handStyle}
          animate={handAnimation}
          transition={handTransition}
          src={handFront}
          alt={''}
        />
      </motion.div>
    </motion.div>
  )
}

export { CharacterConfused }
