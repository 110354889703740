import React, { useReducer } from 'react'
import { reducer, initialState } from '../reducers/appReducer'
import { AppContext } from '../App'

const Context = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {props.children}
    </AppContext.Provider>
  )
}

export { Context }
