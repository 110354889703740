import { motion } from 'framer-motion'
import styled from 'styled-components'
import StyledTextTitle from '../../components/animations/TextTitle/style'

const StyledStart = styled(motion.div)`
  max-width: 375px;
  max-height: 667px;
  margin: auto;
  position: relative;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    text-decoration: none;
  }

  ${StyledTextTitle} {
    top: 190px;
    transform: rotate(-2deg);
    z-index: 2;
  }
`

StyledStart.Body = styled(motion.div)`
  position: relative;
  z-index: 4;
  padding: 0 20px 90px;

  h1 {
    text-align: center;
  }
`

export default StyledStart
