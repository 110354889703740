import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import StyledLoopingText from './style'

const LoopingText = (props) => {
  const [texts, setTexts] = useState([
    'Jag är Rimmo!',
    'En AI-bot som skapar julrim.',
    'AI står för artifisell intelligent.',
    'Mina rim är helt fantastiska.',
    'Testa mina skills!',
  ])
  const [currentText, setCurrentText] = useState('')

  useEffect(() => {
    let i = 0
    setCurrentText(texts[i])

    const interval = setInterval(() => {
      i++
      if (i >= texts.length) i = 0
      setCurrentText(texts[i])
    }, 3000)

    // Cleanup interval
    return () => clearInterval(interval)
  }, [])

  const variants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
      transition: {
        duration: 0.2,
        delay: 0.6,
      },
    },
    out: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  }

  return (
    <StyledLoopingText>
      <motion.div key={'loopingText'} variants={variants}>{currentText}</motion.div>
    </StyledLoopingText>
  )
}

export { LoopingText }
