import PropTypes from 'prop-types'
import StyledInput from './style'
import inputBg from '../../img/inputBg.svg'

const Input = ({ handleChange, value, }) => {
  return (
    <StyledInput>
      <img src={inputBg} alt={''} />
      <input
        type={'text'}
        value={value}
        onChange={handleChange}
      />
    </StyledInput>
  )
}

Input.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
}

export { Input }
