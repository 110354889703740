import styled from 'styled-components'
import { motion } from 'framer-motion'
import { getCharPosition, getCharWidth } from './lookup'

const fullSize = { width: 5904, height: 330 }
const spaceWidth = 40

const StyledTextTitle = styled(motion.div)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

StyledTextTitle.Line = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -${(props) => fullSize.height / props.size / 2.5}px;
`

StyledTextTitle.Char = styled(motion.div)`
  background-image: url(${(props) => props.bgImg});
  background-size: ${(props) => fullSize.width / props.size}px
    ${(props) => fullSize.height / props.size}px;
  background-position-x: ${(props) =>
    getCharPosition(props.char) / props.size}px;
  width: ${(props) => Math.round(getCharWidth(props.char) / props.size)}px;
  height: ${(props) => Math.round(fullSize.height / props.size)}px;
  margin: 0 -${(props) => getCharWidth(props.char) / props.size / 9}px;
  flex: 0 0 ${(props) => Math.round(getCharWidth(props.char) / props.size)}px;
`

StyledTextTitle.Space = styled.div`
  width: ${(props) => Math.round(spaceWidth / props.size)}px;
`

export default StyledTextTitle
