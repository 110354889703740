import {
  UPDATE_CHRISTMAS_GIFT,
  UPDATE_ID,
  UPDATE_RHYMES,
} from '../actions/appActions'

const initialState = {
  christmasGift: '',
  rhymes: [],
  suggestions: [],
  id: null,
  status: {
    code: false,
    loading: false,
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_CHRISTMAS_GIFT:
      return {
        ...state,
        christmasGift: action.payload,
      }
    case UPDATE_RHYMES:
      return {
        ...state,
        rhymes: action.payload,
      }
    case UPDATE_ID:
      return {
        ...state,
        id: action.payload
      }
    default: {
      return state
    }
  }
}

export { initialState, reducer }
