export const UPDATE_CHRISTMAS_GIFT = 'UPDATE_CHRISTMAS_GIFT'
export const updateChristmasGift = (christmasGift) => {
    return {type: UPDATE_CHRISTMAS_GIFT, payload: christmasGift}
}

export const UPDATE_RHYMES = 'UPDATE_RHYMES'
export const updateRhymes = (rhymes = []) => {
    return {type: UPDATE_RHYMES, payload: rhymes}
}

export const UPDATE_ID = 'UPDATE_ID'
export const updateId = (id = null) => {
    return {type: UPDATE_ID, payload: id}
}