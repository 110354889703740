import { motion } from 'framer-motion'
import { characterWrapperVariants } from '../helpers/utils'
import fakkedRimmo from '../../../img/result/fakked/fakkedRimmoNoEyesNoArmsNoShoes.svg'
import fakkedTop from '../../../img/result/fakked/fakkedTop.svg'
import fakkedBrain from '../../../img/result/fakked/fakkedBrain.svg'
import leftShoe from '../../../img/result/fakked/fakkedLeftShoe.svg'
import rightShoe from '../../../img/result/fakked/fakkedRightShoe.svg'
import leftArm from '../../../img/result/fakked/fakkedLeftArm.svg'
import rightArm from '../../../img/result/fakked/fakkedRightArm.svg'
import { CharacterFakkedEyes } from '../Svg'
import { randomFloat, randomInt } from '../../../utilities/helpers'
import { useState } from 'react'

const getBrainFlickerY = () => {
  const len = randomInt(5, 10)
  let values = [0]
  for (let i = 0; i < len; i++) {
    values.push(randomFloat(-4, 8))
  }
  values.push(0)
  return values
}

const getBrainFlickerRotation = () => {
  const len = randomInt(12, 16)
  let values = [0]
  for (let i = 0; i < len; i++) {
    values.push(randomFloat(-3, 3))
  }
  values.push(0)
  return values
}

const CharacterFakked = (props) => {
  const [brainYValues, setbrainYValues] = useState(getBrainFlickerY())
  const [brainRotationValues, setbrainRotationValues] = useState(
    getBrainFlickerRotation()
  )

  return (
    <motion.div
      variants={characterWrapperVariants}
      style={{
        position: 'absolute',
        bottom: '-100px',
        left: 'calc(50% - 143px)',
        originY: 1,
      }}
    >
      {/* Wrapper */}
      <motion.div
        style={{ originY: 1 }}
        animate={{
          // rotate: [0, -2, -2, 4, 4, -1, -1, 1, 1, -1, -1, 2, 2, 0, 0, 2, 2, 0],
          rotate: [0, -2, 0.5, -1, 1, -1.4, 1, 0.5, 1.5, 0],
        }}
        transition={{
          duration: 1.3,
          ease: 'easeInOut',
          loop: Infinity,
        }}
      >
        <motion.img
          src={fakkedTop}
          alt={''}
          style={{
            position: 'absolute',
            top: '-74px',
            left: '50px',
            originY: 1,
            originX: 1,
          }}
          initial={{ rotate: 0 }}
          animate={{
            rotate: 20,
          }}
          transition={{
            type: 'spring',
            duration: 2,
            bounce: 0.8,
            velocity: 0,
            delay: 0.42,
          }}
        />
        <motion.div
          style={{
            position: 'absolute',
            top: '-73px',
            left: '35px',
          }}
          initial={{
            y: 80,
          }}
          animate={{
            y: 0,
          }}
          transition={{
            duration: 0.3,
            ease: 'backOut',
            delay: 0.8,
          }}
        >
          <motion.img
            src={fakkedBrain}
            alt={''}
            style={{
              originY: 1,
            }}
            animate={{ y: brainYValues, rotate: brainRotationValues }}
            transition={{ ease: 'easeInOut', duration: 1.2, repeat: Infinity }}
          />
        </motion.div>

        <motion.img
          src={leftArm}
          alt={''}
          style={{
            position: 'absolute',
            top: '-20px',
            left: '-55px',
            zIndex: 2,
            originX: 0.77,
            originY: 0.95,
          }}
          initial={{
            rotate: -14,
          }}
          animate={{
            rotate: [-14, -14, -24, -24, -14],
          }}
          transition={{
            duration: 2,
            times: [0, 0.5, 0.7, 0.8, 0.85],
            ease: 'easeInOut',
            loop: Infinity,
          }}
        />
        <motion.img
          src={rightArm}
          alt={''}
          style={{
            position: 'absolute',
            top: '-15px',
            right: '-37px',
            zIndex: 2,
            originX: 0.13,
            originY: 0.95,
          }}
          initial={{
            rotate: 5,
          }}
          animate={{
            rotate: [5, 5, 12, 12, 5],
          }}
          transition={{
            duration: 2,
            times: [0, 0.5, 0.7, 0.8, 0.85],
            ease: 'easeInOut',
            loop: Infinity,
          }}
        />

        <img
          src={fakkedRimmo}
          alt={''}
          style={{
            position: 'relative',
            zIndex: 3,
          }}
        />
        <CharacterFakkedEyes />
      </motion.div>
      <motion.img
        src={leftShoe}
        alt={''}
        style={{
          position: 'absolute',
          bottom: '3px',
          left: '0px',
          zIndex: 4,
        }}
      />
      <motion.img
        src={rightShoe}
        alt={''}
        style={{
          position: 'absolute',
          bottom: '3px',
          right: '0px',
          zIndex: 4,
        }}
      />
    </motion.div>
  )
}

export { CharacterFakked }
