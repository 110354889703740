import { useState } from 'react'
import { getRandomItemInArray } from '../../../utilities/helpers'
import { CharacterConfident } from '../CharacterConfident'
import { TextTitle } from '../TextTitle'
import StyledResultStepTwo from './style'

const ResultStepTwo = (props) => {
  const availableLines = [
    { lines: ['måste tänka', 'extra hårt', 'bara!'], size: 5 },
    { lines: ['det här', 'blir lätt!'], size: 4 },
    { lines: ['watch', 'me!'], size: 3.4 },
  ]

  const [randomLine, setRandomLine] = useState(
    getRandomItemInArray(availableLines)
  )

  return (
    <StyledResultStepTwo initial={'initial'} animate={'in'} exit={'out'}>
      <CharacterConfident />
      <TextTitle
        lines={randomLine.lines}
        size={randomLine.size}
        delay={0.5}
        stagger={0.015}
      />
    </StyledResultStepTwo>
  )
}

export { ResultStepTwo }
