import { motion } from 'framer-motion'
import { characterWrapperVariants } from '../helpers/utils'
import rimmoFace from '../../../img/start/rimmoFaceNoEyes.svg'
import rimmoCord from '../../../img/start/rimmoCord.svg'

import { StartCharacterEyes } from '../Svg'

const StartCharacter = (props) => {
  let adaptedVariants = JSON.parse(JSON.stringify(characterWrapperVariants))
  adaptedVariants.in.transition.delay = 1

  return (
    <motion.div
      variants={adaptedVariants}
      style={{
        position: 'absolute',
        top: '104px',
        left: '134px',
        originY: 1,
      }}
    >
      {/* Wrapper */}
      <motion.div
        style={{ originY: 1 }}
        animate={{
          rotate: [5, -5, 4, -7, 7, -4, 5],
        }}
        transition={{
          duration: 5,
          ease: 'easeInOut',
          times: [0, 0.15, 0.35, 0.5, 0.65, 0.85, 1],
          loop: Infinity,
        }}
      >
        {/* Cord Back */}
        <motion.div
          style={{
            position: 'absolute',
            top: '-10px',
            left: '40px',
            zIndex: -1,
            originY: 0.81,
            originX: 0.52,
          }}
          animate={{
            rotate: [0, 15, 5, 20, 0, 15, 0],
          }}
          transition={{
            type: 'spring',
            duration: 5,
            bounce: 0.8,
            velocity: 0,
            times: [0, 0.15, 0.35, 0.5, 0.65, 0.85, 1],
            loop: Infinity,
            delay: 1.3,
          }}
        >
          <img src={rimmoCord} alt={''} />
        </motion.div>

        <img src={rimmoFace} alt={''} />

        <StartCharacterEyes />

        {/* Cord Front */}
        <motion.div
          style={{
            position: 'absolute',
            top: '-50px',
            left: '11px',
            originY: 0.81,
            originX: 0.52,
          }}
          animate={{
            rotate: [25, 40, 30, 45, 25, 35, 25],
          }}
          transition={{
            type: 'spring',
            duration: 5,
            bounce: 0.8,
            velocity: 0,
            times: [0, 0.15, 0.35, 0.5, 0.65, 0.85, 1],
            loop: Infinity,
            delay: 1.1,
          }}
        >
          <img src={rimmoCord} alt={''} />
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export { StartCharacter }
