import { motion } from 'framer-motion'
import styled from 'styled-components'

const StyledResult = styled(motion.div)`
  max-width: 375px;
  max-height: 667px;
  margin: auto;
  position: relative;

  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }
`

StyledResult.Body = styled(motion.div)`
  position: relative;
  z-index: 4;
  padding: 20px 20px 40px;

  h1 {
    text-align: center;
    margin: 0;
  }

  > p {
    font-size: 20px;
    text-align: center;
    opacity: 0;
    margin: 20px 0;
  }
`

StyledResult.Actions = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  max-width: 216px;
  margin: 40px auto 10px;
`

StyledResult.Desktop = styled.div`
  display: block;

@media (max-width: 768px) {
  display: none;
}
`

StyledResult.Mobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`

export default StyledResult
