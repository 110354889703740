import { motion } from 'framer-motion'
import StyledSplash from './style'

import splashBase from '../../../img/result/splashBase.svg'
import splashStar from '../../../img/result/splashStar.svg'

const Splash = (props) => {
  const baseVariants = {
    initial: { scale: 0 },
    in: {
      scale: 1,
      transition: { type: 'spring', damping: 15, mass: 1, stiffness: 300 },
    },
    out: {
      opacity: 0,
      transition: { ease: 'linear', duration: 0.2 },
    },
  }

  const starVariants = {
    initial: { scale: 0 },
    in: (i) => ({
      scale: 1,
      transition: { type: 'spring', duration: 0.8, bounce: 0.7, delay: i },
    }),
    out: {
      opacity: 0,
      transition: { ease: 'linear', duration: 0.2 },
    },
  }

  return (
    <StyledSplash>
      <StyledSplash.Star
        key={'star1'}
        posX={'85%'}
        posY={'-8%'}
        variants={starVariants}
        custom={0.2}
      >
        <motion.img
          src={splashStar}
          alt={''}
          style={{ scale: 1 }}
          animate={{ rotate: -360 }}
          transition={{ ease: 'linear', repeat: Infinity, duration: 4.5 }}
        />
      </StyledSplash.Star>
      <StyledSplash.Star
        key={'star2'}
        posX={'17%'}
        posY={'-5%'}
        variants={starVariants}
        custom={0.3}
      >
        <motion.img
          src={splashStar}
          alt={''}
          style={{ scale: 0.9 }}
          animate={{ rotate: 360 }}
          transition={{ ease: 'linear', repeat: Infinity, duration: 2.5 }}
        />
      </StyledSplash.Star>
      <StyledSplash.Star
        key={'star3'}
        posX={'-7%'}
        posY={'40%'}
        variants={starVariants}
        custom={0.25}
      >
        <motion.img
          src={splashStar}
          alt={''}
          style={{ scale: 0.6 }}
          animate={{ rotate: -360 }}
          transition={{ ease: 'linear', repeat: Infinity, duration: 3 }}
        />
      </StyledSplash.Star>
      <StyledSplash.Star
        key={'star4'}
        posX={'48%'}
        posY={'87%'}
        variants={starVariants}
        custom={0.4}
      >
        <motion.img
          src={splashStar}
          alt={''}
          style={{ scale: 1 }}
          animate={{ rotate: 360 }}
          transition={{ ease: 'linear', repeat: Infinity, duration: 4 }}
        />
      </StyledSplash.Star>
      <StyledSplash.Star
        key={'star5'}
        posX={'68%'}
        posY={'78%'}
        variants={starVariants}
        custom={0.35}
      >
        <motion.img
          src={splashStar}
          alt={''}
          style={{ scale: 0.5 }}
          animate={{ rotate: -360 }}
          transition={{ ease: 'linear', repeat: Infinity, duration: 2 }}
        />
      </StyledSplash.Star>

      <StyledSplash.Base variants={baseVariants}>
        <img src={splashBase} alt={''} />
      </StyledSplash.Base>
    </StyledSplash>
  )
}

export { Splash }
