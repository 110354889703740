import { catchError } from '../utilities/helpers'

const { BACKEND_URL } = require('../utilities/constants')

/**
 * Get autocomplete suggestions
 * @param {*} query
 */
export const getAutocompleteSuggestions = async (query) => {
  try {
    const response = await fetch(`${BACKEND_URL}/word/autocomplete/${query}`)
    const data = await response.json()
    return data
  } catch (err) {
    console.log(err)
  }
}

/**
 * Get generated rhyme
 * @param {*} word 
 */
export const getGeneratedRhyme = async (word) => {
  try {
    const response = await fetch(`${BACKEND_URL}/generate/${word}`)
    const data = await response.json()
    return data
  } catch (err) {
    catchError(err, {
      message: 'Generate failed...'
    })
  }
}