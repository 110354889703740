import styled from 'styled-components'
import { motion } from 'framer-motion'
import StyledSplash from '../Splash/style'
import StyledTextTitle from '../TextTitle/style'

const StyledResultStepOne = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  ${StyledSplash} {
    width: 270px;
  }

  ${StyledTextTitle} {
    top: 50%;
    transform: translateY(-90px) rotate(-14deg);
  }
`

export default StyledResultStepOne
