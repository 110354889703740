import React from 'react'
import './app.css'
import Start from './pages/Start'
import Result from './pages/Result'
import { Route, Switch, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { Footer } from './components/Footer'

// Context
export const AppContext = React.createContext()

function App() {
  const location = useLocation()

  const atRoot = location.pathname === '/'

  return (
    <div className={'app'}>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path='/'>
            <Start />
          </Route>
          <Route exact path='/generera/:word'>
            <Result />
          </Route>
        </Switch>
      </AnimatePresence>
      <Footer atRoot={atRoot} />
    </div>
  )
}

export default App
