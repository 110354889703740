const positions = {
  a: [0, 184],
  b: [196, 388],
  c: [400, 558],
  d: [570, 756],
  e: [772, 970],
  f: [984, 1160],
  g: [1172, 1338],
  h: [1348, 1504],
  i: [1522, 1614],
  j: [1628, 1774],
  k: [1792, 1966],
  l: [1984, 2132],
  m: [2148, 2354],
  n: [2370, 2526],
  o: [2540, 2722],
  p: [2736, 2902],
  q: [2912, 3104],
  r: [3130, 3302],
  s: [3318, 3482],
  t: [3498, 3682],
  u: [3682, 3842],
  v: [3852, 4008],
  w: [4024, 4234],
  x: [4242, 4412],
  y: [4430, 4604],
  z: [4604, 4792],
  å: [4808, 4990],
  ä: [5004, 5184],
  ö: [5202, 5384],
  '!': [5400, 5524],
  '?': [5540, 5692],
  '.': [5712, 5788],
  ',': [5808, 5892],
}

export const getCharPosition = (char) => {
  return 0 - positions[char.toLowerCase()][0]
}

export const getCharWidth = (char) => {
  let startX = positions[char.toLowerCase()][0]
  let endX = positions[char.toLowerCase()][1]
  return endX - startX
}
