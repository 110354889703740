import { motion } from 'framer-motion'
import { characterWrapperVariants } from '../helpers/utils'
import confidentRimmo from '../../../img/result/confident/confidentRimmoNoEyesNoShoes.svg'
import confidentCord from '../../../img/result/confident/confidentCord.svg'
import leftShoe from '../../../img/result/confident/leftShoe.svg'
import rightShoe from '../../../img/result/confident/rightShoe.svg'
import { CharacterConfidentEyes } from '../Svg'

const CharacterConfident = (props) => {
  return (
    <motion.div
      variants={characterWrapperVariants}
      style={{
        position: 'absolute',
        bottom: '60px',
        left: 'calc(50% - 96px)',
        originY: 1,
      }}
    >
      {/* Wrapper */}
      <motion.div
        style={{ originY: 1 }}
        animate={{
          rotate: [0, -2, 2, -2, 0],
        }}
        transition={{
          duration: 3,
          ease: 'easeInOut',
          times: [0, 0.2, 0.5, 0.8, 1],
          loop: Infinity,
        }}
      >
        <img src={confidentRimmo} alt={''} />

        <CharacterConfidentEyes />

        {/* Cord */}
        <motion.div
          style={{
            position: 'absolute',
            top: '-65px',
            left: '39px',
            originY: 0.82,
            originX: 0.49,
          }}
          initial={{ rotate: -90 }}
          animate={{
            rotate: -50,
          }}
          transition={{
            type: 'spring',
            duration: 2.5,
            bounce: 0.8,
            velocity: 0,
            delay: 0.42,
          }}
        >
          <img src={confidentCord} alt={''} />
        </motion.div>
      </motion.div>
      <motion.img
        src={leftShoe}
        alt={''}
        style={{
          position: 'absolute',
          bottom: '3px',
          left: '0px',
        }}
      />
      <motion.img
        src={rightShoe}
        alt={''}
        style={{
          position: 'absolute',
          bottom: '3px',
          right: '0px',
        }}
      />
    </motion.div>
  )
}

export { CharacterConfident }
