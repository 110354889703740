import { Splash } from '../Splash'
import { TextTitle } from '../TextTitle'
import StyledResultStepOne from './style'

const ResultStepOne = (props) => {
  return (
    <StyledResultStepOne
      initial={'initial'}
      animate={'in'}
      exit={'out'}
    >
      <Splash />
      <TextTitle
        lines={['inga', 'problem!']}
        size={3.4}
        delay={0.2}
        stagger={0.04}
      />
    </StyledResultStepOne>
  )
}

export { ResultStepOne }
