import styled from 'styled-components'

const StyledButton = styled.div`
  margin-top: 40px;
`

StyledButton.Button = styled.button`
  background: transparent;
  color: #000;
  font-size: 28px;
  font-family: 'Funkis A';
  font-weight: 600;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  padding: 0 45px;
  text-transform: uppercase;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  > img {
    position: absolute;
    z-index: -1;
    width: 100%;
    margin-top: 2px;
  }
`


export default StyledButton