import './style.js'
import PropTypes from 'prop-types'
import StyledStart from './style'
import { Button } from '../../components/Button'
import { Input } from '../../components/Input'
import { AppContext } from '../../App.js'
import { updateChristmasGift } from '../../actions/appActions.js'
import { useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { TITLE_SUFFIX } from '../../utilities/constants.js'

import { TextTitle } from '../../components/animations/TextTitle/index.js'
import { StartBackground } from '../../components/animations/StartBackground/index.js'
import { LoopingText } from '../../components/animations/LoopingText/index.js'
import { StartCharacter } from '../../components/animations/StartCharacter/index.js'
import { StartCharacterHands } from '../../components/animations/StartCharacterHands/index.js'

const Start = () => {
  const history = useHistory()
  const [{ christmasGift }, dispatch] = useContext(AppContext)

  /**
   * Handle Change
   * @param {*} value
   */
  const handleChange = (value) => {
    dispatch(updateChristmasGift(value))
  }

  /**
   * Set word from url
   */
  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const word = urlParams.get('word')
      if (word) {
        dispatch(updateChristmasGift(word))
        window.history.pushState({}, document.title, "/");
      }
    } catch {}
  },[])

  const url = `/generera/${christmasGift}`

  const handleSubmit = (e) => {
    if (!christmasGift.length) return
    e.preventDefault()
    history.push(url)
  }

  const startBodyVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
      transition: {
        duration: 0.3,
        delay: 1,
      },
    },
    out: {
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
  }

  return (
    <>
      <Helmet title={TITLE_SUFFIX} />
      <StyledStart key={'start'} initial={'initial'} animate={'in'} exit={'out'}>
        <LoopingText />
        <StartBackground />
        <StartCharacter />
        <TextTitle key={'StartTextTitle'} lines={['rimmo!']} stagger={0.08} />
        <StartCharacterHands />
        <StyledStart.Body key={'startBody'} variants={startBodyVariants}>
          <h1>Vilken julklapp vill du rimma på?</h1>
          <form onSubmit={handleSubmit}>
            <Input
              handleChange={(e) => handleChange(e.target.value)}
              value={christmasGift}
            />
          </form>
          <Link to={christmasGift.length > 0 ? url : null}>
            <Button text={'Hit me!'} />
          </Link>
        </StyledStart.Body>
      </StyledStart>
    </>
  )
}

Start.propTypes = {
  title: PropTypes.string,
  handleClick: PropTypes.func,
  handleChange: PropTypes.func,
  value: PropTypes.string,
}

export default Start
