import styled from 'styled-components'
import { motion } from 'framer-motion'

const StyledSplash = styled.div`
  position: relative;
`

StyledSplash.Base = styled(motion.div)``

StyledSplash.Star = styled(motion.div)`
  position: absolute;
  top: ${(props) => props.posY};
  left: ${(props) => props.posX};
`

export default StyledSplash
