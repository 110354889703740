import { useState } from 'react'
import { getRandomItemInArray } from '../../../utilities/helpers'
import { CharacterConfused } from '../CharacterConfused'
import { TextTitle } from '../TextTitle'
import StyledResultStepThree from './style'

const ResultStepThree = (props) => {
  const availableLines = [
    { lines: ['nngggggh!'], size: 3.8 },
    { lines: ['ummmmm...'], size: 3.6 },
    { lines: ['ppffft...'], size: 3.2 },
  ]

  const [randomLine, setRandomLine] = useState(
    getRandomItemInArray(availableLines)
  )

  return (
    <StyledResultStepThree initial={'initial'} animate={'in'} exit={'out'}>
      <CharacterConfused />
      <TextTitle
        lines={randomLine.lines}
        size={randomLine.size}
        delay={0.5}
        stagger={0.015}
      />
    </StyledResultStepThree>
  )
}

export { ResultStepThree }
