import './style.js'
import PropTypes from 'prop-types'
import StyledRoundButton from './style'

const RoundButton = (props) => {
  const { onClick, text, icon, href, target, download } = props
  const Component = href ? StyledRoundButton.A : StyledRoundButton
  return (
    <Component onClick={onClick} target={target} href={href} download={download}>
      <StyledRoundButton.Icon>
        <img src={icon} alt={''} />
      </StyledRoundButton.Icon>
      <p>{text}</p>
    </Component>
  )
}

RoundButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.any,
  download: PropTypes.bool,
}

export { RoundButton }
