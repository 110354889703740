import PropTypes from 'prop-types'
import StyledTextTitle from './style'
import charsSpriteSheet from '../../../img/chars_spritesheet.png'

const TextTitle = ({ lines, size = 3, delay = 0.2, stagger = 0.08 }) => {
  const titleVariants = {
    initial: {},
    in: {
      transition: {
        delayChildren: delay,
        staggerChildren: stagger,
        // staggerDirection: -1,
      },
    },
    out: {
      // opacity: 0,
      transition: {
        delayChildren: 0,
        staggerChildren: 0.015,
        // staggerDirection: -1,
      },
    },
  }

  const charVariants = {
    initial: { opacity: 0, scale: 0.3 },
    in: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', duration: 0.8, bounce: 0.55 },
    },
    out: {
      opacity: 0,
      scale: 0.3,
      transition: { type: 'spring', duration: 0.8, bounce: 0.55 },
    },
  }

  return (
    <StyledTextTitle variants={titleVariants}>
      {lines.map((line, lineIndex) => {
        const chars = line.split('')
        return (
          <StyledTextTitle.Line key={lineIndex} size={size}>
            {chars.map((char, charIndex) => {
              return char !== ' ' ? (
                <StyledTextTitle.Char
                  key={`${lineIndex}_${charIndex}`}
                  bgImg={charsSpriteSheet}
                  size={size}
                  char={char}
                  variants={charVariants}
                />
              ) : (
                <StyledTextTitle.Space
                  key={`${lineIndex}_${charIndex}`}
                  size={size}
                />
              )
            })}
          </StyledTextTitle.Line>
        )
      })}
    </StyledTextTitle>
  )
}

TextTitle.propTypes = {
  lines: PropTypes.array,
  delay: PropTypes.number,
  stagger: PropTypes.number,
}

export { TextTitle }
