import styled from 'styled-components'
import { motion } from 'framer-motion'

const StyledStartBackground = styled.div`
  position: absolute;
  top: 88px;
  width: 100%;
`
StyledStartBackground.ArcWrapper = styled(motion.div)`

`

StyledStartBackground.Arc = styled.div`
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  width: 3000px;
  height: 3000px;
  transform: translateX(-50%) translateY(568px);

  div {
    width: 3000px;
    height: 3000px;
    transform: rotate(45deg);
    border-radius: 500px;
    background: #ffe4e4;
    border: 3px solid black;
  }
`

export default StyledStartBackground
