import StyledStartBackground from './style'
import { motion } from 'framer-motion'
import leftPackage from '../../../img/start/leftPackage.svg'
import middlePackage from '../../../img/start/middlePackage.svg'
import rightPackage from '../../../img/start/rightPackage.svg'

const StartBackground = (props) => {
  const arcVariants = {
    initial: { y: '100vh' },
    in: {
      y: '0vh',
      transition: { type: 'spring', damping: 20, mass: 1, stiffness: 180 },
    },
    out: {
      y: '100vh',
      transition: { ease: 'backIn', duration: 0.4 },
    },
  }

  const packageVariants = {
    initial: { scaleY: 0.5, opacity: 0 },
    in: (i) => ({
      scaleY: 1,
      opacity: 1,
      transition: { type: 'spring', duration: 0.6, bounce: 0.6, delay: i },
    }),

    out: {
      opacity: 0,
      transition: { ease: 'linear', duration: 0.1 },
    },
  }

  return (
    <StyledStartBackground>
      <motion.div
        key={'leftPackage'}
        style={{ position: 'absolute', originY: 1, x: 3, y: 105, rotate: -13 }}
        variants={packageVariants}
        custom={0.4}
      >
        <img src={leftPackage} alt={''} />
      </motion.div>
      <motion.div
        key={'middlePackage'}
        style={{ position: 'absolute', originY: 1, x: 110, y: 10, rotate: -5 }}
        variants={packageVariants}
        custom={0.55}
      >
        <img src={middlePackage} alt={''} />
      </motion.div>
      <motion.div
        key={'rightPackage'}
        style={{ position: 'absolute', originY: 1, x: 260, y: 86, rotate: 10 }}
        variants={packageVariants}
        custom={0.7}
      >
        <img src={rightPackage} alt={''} />
      </motion.div>

      <StyledStartBackground.ArcWrapper
        key={'ArcWrapper'}
        variants={arcVariants}
      >
        <StyledStartBackground.Arc>
          <div />
        </StyledStartBackground.Arc>
      </StyledStartBackground.ArcWrapper>
    </StyledStartBackground>
  )
}

export { StartBackground }
