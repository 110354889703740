import { useState } from 'react'
import { getRandomItemInArray } from '../../../utilities/helpers'
import { CharacterFakked } from '../CharacterFakked'
import { TextTitle } from '../TextTitle'
import StyledResultStepFour from './style'

const ResultStepFour = (props) => {
  const availableLines = [
    { lines: ['kom igen,', 'hjärnan!'], size: 3.6 },
    { lines: ['sista', 'biten nu!'], size: 3.4 },
    { lines: ['ta i allt', 'du har!'], size: 3.6 },
  ]

  const [randomLine, setRandomLine] = useState(
    getRandomItemInArray(availableLines)
  )

  return (
    <StyledResultStepFour initial={'initial'} animate={'in'} exit={'out'}>
      <CharacterFakked />
      <TextTitle
        lines={randomLine.lines}
        size={randomLine.size}
        delay={0.5}
        stagger={0.02}
      />
    </StyledResultStepFour>
  )
}

export { ResultStepFour }
