import styled from 'styled-components'

const StyledFooter = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 100;
  padding: 0 0 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  background: ${(props) => (props.atRoot ? 'transparent' : 'white')};
  transition: background-color .15s .15s;

  a {
    color: black;
  }
`

StyledFooter.Inner = styled.div`
  margin: 0 10px;
  max-width: 375px;
  width: 100%;
`

StyledFooter.Line = styled.div`
  display: flex;
  margin-bottom: 10px;

  div {
    height: 4px;
    background: black;
    border-radius: 2px;
    width: 10px;
    margin: 0 3px;

    &:nth-child(1) {
      width: 4px;
    }

    &:nth-child(2) {
      width: 4px;
    }

    &:nth-child(3) {
      flex: 1;
    }

    &:nth-child(4) {
      width: 60px;
    }

    &:nth-child(5) {
      width: 4px;
    }
  }
`

export default StyledFooter
