import styled, { css } from 'styled-components'

const buttonStyle = () => css`
  position: relative;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 20px;
  margin: 0;
  cursor: pointer;
  color: black;

  &:focus {
    outline: none;
  }

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    white-space: nowrap;
  }
`

const StyledRoundButton = styled.button`
  ${buttonStyle()}
`
StyledRoundButton.A = styled.a`
  ${buttonStyle()}
`

StyledRoundButton.Icon = styled.div`
  background: black;
  border-radius: 100%;
  position: relative;
  height: 40px;
  width: 40px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export default StyledRoundButton
