import React from 'react'
import ReactDOM from 'react-dom'
import './fonts.css'
import './index.css'
import App from './App'
import { Context } from './context/context'
import { HashRouter } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// import reportWebVitals from './reportWebVitals';


/**
 * Prod
 */
if (process.env.NODE_ENV === 'production') {
  TagManager.initialize({
    gtmId: 'GTM-WPQCZDT'
  })
  Sentry.init({
    dsn: "https://75e6f480e68546ed816276152e103711@o245925.ingest.sentry.io/5562038",
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}


ReactDOM.render(
  <Context>
      <HashRouter>
        <App />
      </HashRouter>
  </Context>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
