import { useState } from 'react'
import { getRandomItemInArray } from '../../../utilities/helpers'
import { CharacterHappy } from '../CharacterHappy'
import { Splash } from '../Splash'
import { TextTitle } from '../TextTitle'
import StyledResultLastStep from './style'

const ResultLastStep = (props) => {
  const availableLines = [
    { lines: ['där satt', 'den!'], size: 3 },
    { lines: ['slam', 'dunk!'], size: 2.8 },
    { lines: ['så jävla', 'bra!'], size: 3 },
    { lines: ['booo', 'yah!'], size: 3 },
  ]

  const [randomLine, setRandomLine] = useState(
    getRandomItemInArray(availableLines)
  )

  return (
    <StyledResultLastStep initial={'initial'} animate={'in'} exit={'out'}>
      <Splash />
      <CharacterHappy />
      <TextTitle
        lines={randomLine.lines}
        size={randomLine.size}
        delay={0.5}
        stagger={0.02}
      />
    </StyledResultLastStep>
  )
}

export { ResultLastStep }
