import './style.js'
import PropTypes from 'prop-types'
import StyledButton from './style'
import buttonBg from '../../img/buttonBg.svg'

const Button = (props) => {
  const { handleClick, text } = props
  return (
    <StyledButton>
      <StyledButton.Button onClick={handleClick}>
        <img src={buttonBg} alt={''} />
        {text}
      </StyledButton.Button>
    </StyledButton>
  )
}

Button.propTypes = {
  handleClick: PropTypes.func,
  text: PropTypes.string,
}

export { Button }
